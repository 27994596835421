<template>
  <!-- 村通客运管理--线路管理 -->
  <div ref="villagePassLineManage" class="villagePassLineManage">
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">线路代码：</div>
        <div class="col-2">
          <el-input
            v-model="queryForm.code"
            placeholder="请输入线路代码"
            clearable
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">线路名称：</div>
        <div class="col-2 col-2-a">
          <el-input
            v-model="queryForm.name"
            placeholder="请输入线路名称"
            clearable
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">所属公司：</div>
        <div class="col-2 col-2-a">
          <companySelect
            ref="companyRef"
            :clearable="true"
            :company-tree="companyList"
            @getValue="getAddGroupId"
          ></companySelect>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">停靠点：</div>
        <div class="col-2 col-2-a">
          <el-input
            v-model="queryForm.midwaySiteKeyWord"
            placeholder="请输入停靠点"
            clearable
          ></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">运营县区：</div>
        <div class="col-2 col-2-a">
          <el-select
            v-model="queryForm.operationArea"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in areaAllList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">分类：</div>
        <div class="col-2 col-2-a">
          <el-select
            v-model="queryForm.lineClass"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in classificationList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="searchLine"
          >查询</el-button
        >
        <el-button type="primary" size="small" @click="exportFun"
          >导出</el-button
        >
        <el-button type="primary" size="small" @click="addFun">新增</el-button>
      </div>
    </div>
    <Table
      :col-class-type="true"
      :operation="true"
      :table-data="tableData"
      :title-name="titleName"
      :table-height="TableHeight"
      :operation-width="180"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            type="text"
            size="small"
            sort="primary"
            @click="editFun(scope.scopeRow)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="danger"
            @click="deleteFun(scope.scopeRow)"
            >删除</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="addMiddleSites(scope.scopeRow)"
            >停靠点</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper, sizes"
        :total="total"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 新增、编辑弹窗 -->
    <charterPopup
      v-if="editorAndAddShow"
      width="550"
      :header-text="headerText"
      @closePopupFun="closePopupFun"
      @confirmFun="confirmFun"
    >
      <div class="contentPopup">
        <el-form
          ref="Form"
          :model="addForm"
          :rules="rules"
          label-width="120px"
          label-position="right"
          class="demo-form dialog-form"
        >
          <el-form-item label="线路代码：" prop="code">
            <el-input
              v-model="addForm.code"
              clearable
              placeholder="请输入线路代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="线路名称：" prop="name">
            <el-input
              v-model="addForm.name"
              clearable
              placeholder="请输入线路名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属公司：" prop="companyId">
            <companySelect
              v-if="editorAndAddShow"
              ref="companyRefTwo"
              :company-tree="companyList"
              :valuename="addForm.companyName"
              :value="addForm.companyId"
              @getValue="getAddGroupIdShow"
            ></companySelect>
          </el-form-item>
          <el-form-item label="运营县区：" prop="operationArea">
            <el-select v-model="addForm.operationArea" placeholder="请选择">
              <el-option
                v-for="item in areaAllList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类：" prop="lineClass">
            <el-select v-model="addForm.lineClass" placeholder="请选择">
              <el-option
                v-for="item in classificationList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运营时段：">
            <el-time-picker
              v-model="addForm.operatingTime"
              is-range
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              format="HH:mm"
            ></el-time-picker>
          </el-form-item>
          <el-form-item label="客服电话：" prop="servicePhone">
            <div class="addNumberBox">
              <div
                v-for="(item, index) in addForm.servicePhone"
                :key="index"
                style="position: relative"
              >
                <el-input
                  v-model.trim="addForm.servicePhone[index]"
                  clearable
                  maxlength="11"
                  placeholder="请输入客服电话号码"
                  onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                ></el-input>
                <el-button
                  v-show="index >= 1"
                  class="reduce"
                  @click="reducecustomerFun(index)"
                  >-</el-button
                >
              </div>
              <el-button
                v-show="this.addForm.servicePhone.length < 5"
                class="add"
                @click="addcustomerFun"
                >+</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="状态：" prop="status">
            <el-switch
              v-model="addForm.status"
              active-color="#13ce66"
              inactive-color="#E3E3E3"
              @change="statusSwitch"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
    </charterPopup>
  </div>
</template>

<script>
import { getCompanyTree } from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import moment from "moment";
import { formatDate } from "@/common/utils/index";
import {
  queryLinePage,
  allArea,
  villageLineAdd,
  villageLineUpDate,
  villageLineDelete,
  villageLineExport,
} from "@/api/lib/customizedBus.js";
export default {
  components: {
    companySelect,
  },
  data() {
    let validateDate = (rule, value, callback) => {
      if (value && value !== "") {
        let day = formatDay(new Date());
        let startTime = `${day} ${formatDate(value[0]).split(" ")[1]}`;
        let endTime = `${day} ${formatDate(value[1]).split(" ")[1]}`;
        if (new Date(startTime) >= new Date(endTime)) {
          callback(new Error(`开始时间必须小于结束时间`));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      TableHeight: 0,
      queryForm: {
        code: "",
        name: "",
        midwaySiteKeyWord: "",
        companyId: null,
        operationArea: null, //运营县区
        lineClass: "",
        currentPage: 1,
        pageSize: 10,
      },
      companyList: [],
      areaAllList: [], //运营县区列表
      tableData: [],
      total: 0,
      classificationList: [
        { id: 0, name: "固定班线" },
        { id: 1, name: "无固定班线" },
      ],
      titleName: [
        {
          title: "线路代码",
          props: "code",
        },
        {
          title: "线路名称",
          props: "name",
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "运营县区",
          props: "operationArea",
        },
        {
          title: "分类",
          props: "lineClass",
          SpecialJudgment: (res) => {
            return res === 0 ? "固定班线" : res === 1 ? "无固定班线" : "-";
          },
        },
        {
          title: "运营时段",
          props: "operatingTime",
        },
        {
          title: "客服电话",
          props: "servicePhone",
          SpecialJudgment: (res) => {
            let text = "";
            text = res.join(";");
            return text;
          },
        },
        {
          title: "启用",
          props: "status",
          width: 70,
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  let obj = {
                    ...params,
                    status: params.status === 0 ? 1 : 0,
                  };
                  if (val) {
                    if (params.siteList && params.siteList.length >= 2) {
                      this.renderDataUpdateFun(obj);
                    } else {
                      this.$message.warning("请至少添加两个停靠点再启用");
                    }
                  } else {
                    this.renderDataUpdateFun(obj);
                  }
                },
              },
            });
          },
        },
      ],
      /**新增编辑 */
      editorAndAddShow: false,
      headerText: "",
      addForm: {
        companyName: "",
        companyId: null, //公司ID
        name: "", //线路名称
        code: "", //线路代码
        servicePhone: [""], //客服电话
        operatingTime: [
          new Date(2016, 9, 10, 6, 0),
          new Date(2016, 9, 10, 22, 0),
        ], //运营时间
        operationArea: "", //运营区县
        lineClass: null, //线路类型
        status: 0, //状态
        siteList: [],
      },
      rules: {
        code: [{ required: true, message: "请输入线路代码", trigger: "blur" }],
        name: [{ required: true, message: "请输入线路名称", trigger: "blur" }],
        companyId: [
          { required: true, message: "请选择所属公司", trigger: "blur" },
        ],
        operationArea: [
          { required: true, message: "请选择运营县区", trigger: "blur" },
        ],
        lineClass: [{ required: true, message: "请选择分类", trigger: "blur" }],
        servicePhone: [
          { required: true, validator: this.customerPhoneFun, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getCompanyList();
    this.searchLine();
    this.queryAllAreaList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
      console.log(this.TableHeight);
    });
  },
  watch: {
    searchInput: {
      handler(val) {
        if (val) this.searchFun(val);
      },
    },
  },
  methods: {
    computeHeight() {
      const wholeHeight = this.$refs.villagePassLineManage.clientHeight;
      this.TableHeight = wholeHeight - 270 + "px";
    },
    //删除
    deleteFun(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除？")]),
        showCancelBeditFunutton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          villageLineDelete({ id: scope.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("删除成功");
              this.searchLine();
            }
          });
        })
        .catch(() => {});
    },
    //编辑
    editFun(scope) {
      this.editorAndAddShow = true;
      this.isEditor = true;
      this.headerText = "编辑线路";
      this.addForm = { ...scope };
      this.addForm.status = scope.status ? false : true;
      if (this.addForm.operatingTime) {
        this.addForm.operatingTime = [
          new Date(
            2016,
            9,
            10,
            scope.operatingTime.split("-")[0].split(":")[0],
            scope.operatingTime.split("-")[0].split(":")[1]
          ),
          new Date(
            2016,
            9,
            10,
            scope.operatingTime.split("-")[1].split(":")[0],
            scope.operatingTime.split("-")[1].split(":")[1]
          ),
        ];
      }
      this.$nextTick(() => {
        this.$refs.companyRefTwo.initHandle();
      });
    },

    //获取可用运营区县列表
    queryAllAreaList() {
      allArea().then((res) => {
        if (res.code === "SUCCESS") {
          this.areaAllList = res.data || [];
        }
      });
    },
    closePopupFun() {
      this.editorAndAddShow = false;
      this.isEditor = false;
      this.headerText = "";
      this.addForm = {
        companyName: "",
        companyId: null, //公司ID
        name: "", //线路名称
        code: "", //线路代码
        servicePhone: [""], //客服电话
        operatingTime: [
          new Date(2016, 9, 10, 6, 0),
          new Date(2016, 9, 10, 22, 0),
        ], //运营时间
        operationArea: null, //运营区县
        lineClass: null, //线路类型
        status: 0, //状态
        siteList: [],
      };
    },
    renderDataUpdateFun(obj) {
      villageLineUpDate(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("修改成功");
          this.closePopupFun();
          this.searchLine();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    statusSwitch(val) {
      const arr = this.addForm.siteList;
      if (val) {
        if ((arr && arr.length < 2) || !arr) {
          this.addForm.status = false;
          this.$message.warning("请至少添加两个停靠点再启用");
        }
      }
    },
    confirmFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          let time = null;
          if (this.addForm.operatingTime) {
            time = `${moment(new Date(this.addForm.operatingTime[0])).format(
              "HH:mm"
            )}-${moment(new Date(this.addForm.operatingTime[1])).format(
              "HH:mm"
            )}`;
          }
          const data = { ...this.addForm };
          data.operatingTime = time;
          data.status = data.status ? 0 : 1;
          if (this.isEditor) {
            this.renderDataUpdateFun(data);
          } else {
            villageLineAdd(data)
              .then((res) => {
                if (res.code === "SUCCESS") {
                  this.$message.success("新增成功！");
                  this.closePopupFun();
                  this.searchLine();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
    },
    addFun() {
      this.editorAndAddShow = true;
      this.headerText = "新增";
    },
    getAddGroupIdShow(v) {
      this.addForm.companyId = v;
    },
    // 客服电话校验规则
    customerPhoneFun(rule, value, callback) {
      let reg = /^(([0][1-9]{2,3}[0-9]{5,10})|(1(3|4|5|6|7|8|9)\d{9}))$/;
      if (
        this.addForm.servicePhone.length == 1 &&
        this.addForm.servicePhone[0] == ""
      ) {
        callback("请至少填写一个客服电话");
      } else {
        let con = true;
        for (let j = 0; j < this.addForm.servicePhone.length; j++) {
          if (reg.test(this.addForm.servicePhone[j]) == false) {
            callback("客服电话号码格式错误");
            con = false;
            break;
          }
        }
        con && callback();
      }
    },
    // 点击添加客服电话
    addcustomerFun() {
      const len = this.addForm.servicePhone.length - 1;
      if (this.addForm.servicePhone[len].trim() == "") {
        this.$message.error("请输入客服号码后再进行添加");
      } else {
        this.addForm.servicePhone.push("");
      }
    },
    // 点击去掉客服电话号码
    reducecustomerFun(index) {
      this.addForm.servicePhone.splice(index, 1);
    },
    // 企业回显
    getAddGroupId(v) {
      this.queryForm.companyId = v;
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    searchLine() {
      queryLinePage(this.queryForm).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    onCurrentChange(val) {
      this.queryForm.currentPage = val;
      this.searchLine();
    },
    onSizeChange(size) {
      this.queryForm.pageSize = size;
      this.queryForm.currentPage = 1;
      this.searchLine();
    },
    exportFun() {
      villageLineExport(this.queryForm).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "村通客运线路表" + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    //添加停靠点
    addMiddleSites(row) {
      this.$router.push({
        path: "/projectTravel/addMiddleSites",
        query: { id: row.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.villagePassLineManage {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  background: #ffffff;
  padding: 16px 20px;
  position: relative;
  .ActionBar {
    background: #f7f8f9;
    padding: 16px 0 0 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    height: 114px;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box {
      width: calc(25% - 18px);
      height: 32px;
      line-height: 32px;
      margin-right: 7px;
      margin-bottom: 16px;
      padding-right: 10px;
      .col-1-a {
        width: 90px !important;
      }
      .col-1 {
        width: 90px;
        display: inline-block;
      }
      .col-2-a {
        width: calc(100% - 110px) !important;
      }
      .col-2 {
        display: inline-block;
        width: calc(100% - 110px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
/**线路规划 */
.searchBox {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 230px !important;
  height: 28px !important;
  z-index: 2056;
  /deep/.el-input {
    input {
      padding-left: 30px;
    }
  }
  i {
    position: absolute;
    font-size: 20px;
    z-index: 2057;
    top: 4px;
    left: 6px;
  }
  .searchContent {
    max-height: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #ffffff;
  }
  .address {
    width: calc(100% - 8px);
    display: inline-block;
    cursor: pointer;
    padding: 4px 4px 0 4px;
    line-height: 24px;
  }
  .addressDetail {
    width: calc(100% - 8px);
    display: inline-block;
    border-bottom: 1px solid #f4f4f4;
    cursor: pointer;
    padding: 0 4px 4px 4px;
    line-height: 24px;
    font-size: 12px;
    color: #afafaf;
  }
}
/deep/.el-textarea__inner {
  //el_input中的隐藏属性
  resize: none; //主要是这个样式
  height: 100px;
}
.plan-line {
  width: 100%;
  height: 100%;
  background: #f7f8f9;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 22;
  overflow: hidden;
  .map-box {
    border-radius: 16px;
    background: #ffffff;
    padding: 10px;
    height: calc(100% - 66px);
    position: relative;
    .map {
      width: 100%;
      height: 100%;
    }
    .mapMask {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  }
}
/**客服电话 */
.addNumberBox {
  position: relative;
  .add {
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    top: 8px;
    left: 210px;
  }
  .reduce {
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    bottom: 8px;
    left: 210px;
  }
}
</style>
